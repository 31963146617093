import jump from 'jump.js';
import { Loader } from '@googlemaps/js-api-loader';
import markerIcon from './images/marker.png';

window.addEventListener('DOMContentLoaded', init);

function init() {
  activateMenuLinks();
  activateHamburger();
  initMap();
}

function activateHamburger() {
  const hamburger = document.querySelector('[data-hamburger]');
  const nav = document.querySelector('[data-nav]');

  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('is-active');
    nav.classList.toggle('is-active');
  });
}

function activateMenuLinks() {
  const links = document.querySelectorAll('[data-jump]');
  const hamburger = document.querySelector('[data-hamburger]');
  const nav = document.querySelector('[data-nav]');

  links.forEach(link => {
    link?.addEventListener('click', e => {
      e.preventDefault();
      const anchor = `#${link.href.split('#')[1]}`;
      jump(anchor);

      hamburger.classList.toggle('is-active');
      nav.classList.toggle('is-active');
    });
  });
}

function initMap() {
  const loader = new Loader({
    apiKey: 'AIzaSyCz6BmgGC3qaQbzfxlC-UQtrqwJSphW3VM',
    version: 'weekly',
  });
  const styles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  loader.load().then(() => {
    const cords = { lat: 50.06030828095111, lng: 19.96224970497275 };
    const map = new google.maps.Map(document.getElementById('map'), {
      center: cords,
      zoom: 16,
      styles,
    });

    const marker = new google.maps.Marker({
      position: cords,
      map: map,
      icon: markerIcon,
    });
  });
}
